import React from "react";
import useFirestore from "../../hooks/useFirestore";
import { motion } from "framer-motion";

const ImageGrid = ({ setSelectedImg }) => {
  const { docs } = useFirestore("image");
  console.log(docs);
  return (
    <div className="img-grid">
      {docs &&
        docs.map(doc => {
          return (
            <motion.div
              className="img-wrap"
              key={doc.id}
              layout
              whileHover={{ opacity: 1 }}
              s
              onClick={() => setSelectedImg(doc.url)}
            >
              <motion.img
                alt=""
                src={doc.url}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
              ></motion.img>
            </motion.div>
          );
        })}
    </div>
  );
};

export default ImageGrid;
