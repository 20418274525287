import React from "react";

const Title = () => {
  return (
    <div className="title">
      <div>
      <img src="Panda Logo.jpg" alt='panda logo' />
      </div>
      {/* <h1>Panda</h1> */}
      
      <h2>Your Pictures</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  );
};

export default Title;

